body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    line-height: 1.5;
}

.login_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 6%;
    width: 380px;
}

.auth-buttons {
    display: flex;
    justify-content: space-between;
}

.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
}