.main-navbar {
    display: flex;
    height: 100%;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 70%;
}

.middle-container {
    margin: auto;
}

.mobile-view {
    display: flex;
}

.mobile-icon {
    display: none;
}

@media all and (max-width: 1000px) {
    .mobile-view {
        display: none;
    }
    .mobile-icon {
        display: flex;
        position: fixed;
        right: 20px;
    }
    .main-navbar {
        margin: 0;
    }
}