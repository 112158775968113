@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "~@blueprintjs/core/lib/scss/variables";
@import "~@blueprintjs/table/lib/css/table.css";

// then the docs theme styles
@import "~@blueprintjs/docs-theme/lib/css/docs-theme.css";
@import "~@blueprintjs/docs-theme/src/styles/variables";

.bp4-dark {
  background-color: #282828;
  background-image: radial-gradient(#464646 1.6500000000000001px, #282828 1.6500000000000001px);
  background-size: 33px 33px;

}

.bp4-light {
  background-color: #ffffff;
  //background-color: #e9e9e9;
  //background-image: radial-gradient(#b6b6b6 1.6500000000000001px, #e9e9e9 1.6500000000000001px);
  //background-size: 33px 33px;
}

.content {
  margin: 20px;
}

a:hover {
  text-decoration: none;
}

.bp4-dialog-header  {
  max-height: 35px;
  width: 100%;
}

.bp4-elevation-2 {
  -webkit-box-shadow: 0 0 0 1px rgb(17 20 24 / 10%), 0 1px 1px rgb(17 20 24 / 20%), 0 2px 6px rgb(17 20 24 / 20%);
  box-shadow: 0 0 0 1px rgb(17 20 24 / 10%), 0 1px 1px rgb(17 20 24 / 20%), 0 2px 6px rgb(17 20 24 / 20%);
}

.bp4-elevation-1 {
  -webkit-box-shadow: 0 0 0 1px rgb(17 20 24 / 10%), 0 1px 1px rgb(17 20 24 / 20%);
  box-shadow: 0 0 0 1px rgb(17 20 24 / 10%), 0 1px 1px rgb(17 20 24 / 20%);
}

.bp4-elevation-3 {
  -webkit-box-shadow: 0 0 0 1px rgb(17 20 24 / 10%), 0 2px 4px rgb(17 20 24 / 20%), 0 8px 24px rgb(17 20 24 / 20%);
  box-shadow: 0 0 0 1px rgb(17 20 24 / 10%), 0 2px 4px rgb(17 20 24 / 20%), 0 8px 24px rgb(17 20 24 / 20%);
}

.bp4-elevation-4 {
  -webkit-box-shadow: 0 0 0 1px rgb(17 20 24 / 10%), 0 4px 8px rgb(17 20 24 / 20%), 0 18px 46px 6px rgb(17 20 24 / 20%);
  box-shadow: 0 0 0 1px rgb(17 20 24 / 10%), 0 4px 8px rgb(17 20 24 / 20%), 0 18px 46px 6px rgb(17 20 24 / 20%);
}

.bp4-card {
  width: 100%;
  overflow-x: auto;
  padding: 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.pagination {
  padding: 7px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.pagination-text {
  padding: 0 20px;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.bp4-dialog-footer-fixed {
  padding: 0!important;
  width: 100%;
}